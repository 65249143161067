import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Hero from '../../Components/Hotels/HeroHotels';
import HotelDescription from '../../Components/Hotels/HotelDescription';
import BentoGrid from '../../Components/bentoGrid/BentoGrid';
import ServiceCard from '../../Components/Hotels/Globals/ServicesCard/ServiceCard';
import AnimatedComponent from '../../Components/AnimatedComponent';
import smoothScrollTo from '../../utils/SmoothScroll';
import { apiPublicUrl, apiUrl } from '../../libs/configs/variablesEntornos';

const HotelDetail = () => {
  const { slug, idHotel } = useParams();
  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  // Manejar scroll automático a secciones con hash en la URL
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        smoothScrollTo(element, 1500);
      }
    }
  }, [location]);



  const  gallerySectionRef = useRef(null);

  const scrollToSection2 = () => {
    gallerySectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  // Consulta a la API para obtener los datos del hotel
  useEffect(() => {
    const fetchHotel = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/public/hotel/${idHotel}`);
        if (!response.ok) throw new Error('Error al obtener los datos del hotel');
        const data = await response.json();
        setHotel(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHotel();
  }, [idHotel, slug]); // Se recarga cuando cambian `idHotel` o `slug`

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!hotel) return <p>No se encontraron datos del hotel.</p>;

  // Formatear imágenes
  const images = hotel.imagenes || [];
  const imagesWithFullUrl = images.map((image) => ({
    ...image,
    url: `${apiPublicUrl}${image.url}`,
  }));

  return (
    <div className="mb-10">
      <Hero
        title={hotel.nombre}
        description={hotel.descripcion}
        backgroundImage={hotel.imagen_default ? `${apiPublicUrl}${hotel.imagen_default}` : "/images/default-hotel.webp"}
        hotel={hotel.idHotel}
        onGalleryClick={scrollToSection2}
      />

      <div id="section-1">
        <HotelDescription hotelId={idHotel} />
      </div>

      <section ref={gallerySectionRef} className="container pt-20">
        <AnimatedComponent animationType="zoomIn" delay={1}>
          <BentoGrid images={imagesWithFullUrl} />
        </AnimatedComponent>
      </section>

      <div className="container" id="section-3">
        <AnimatedComponent animationType="zoomIn" delay={1}>
          <ServiceCard servicios={hotel.servicios} />
        </AnimatedComponent>
      </div>
    </div>
  );
};

export default HotelDetail;