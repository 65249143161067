import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { apiUrl } from "../../../libs/configs/variablesEntornos";

const FooterContainer = () => {
  const [newHotels, setNewHotels] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiUrl}/public/hotels`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        setNewHotels(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const links = [
    {
      title: "Enlaces Rápidos",
      items: [
        { path: "/", label: "Inicio" },
        { path: "/nosotros", label: "Nosotros" },
        { path: "/hoteles", label: "Hoteles" },
      ],
    },
    {
      title: "Hoteles",
      items: newHotels.map((hotel) => ({
        path: `/hoteles/${hotel.nombre.toLowerCase().replace(/\s+/g, "-")}/${hotel.id}/`,
        label: hotel.nombre,
      })),
    },
    {
      title: "Contacto",
      items: [
        { path: "/contacto", label: "CABA, Buenos Aires, Argentina." },
        { path: "/contacto", label: "reservas@magnahoteles.com" },
        { path: "/contacto", label: "11 5365-5328" },
      ],
    },
    {
      title: "Legal",
      items: [
        { path: "/terminos", label: "Términos de servicio" },
        { path: "/privacidad", label: "Política de privacidad" },
        { path: "/cookies", label: "Política de cookies" },
        { path: "/legal", label: "Aviso legal" },
        { path: "/arrepentimientodecompra", label: "Arrepentimiento de compra" },
      ],
    },
  ];

  const socialLinks = [
    { url: "https://facebook.com/magnahotelesok", icon: <FaFacebookF size={28} /> },
    { url: "https://instagram.com/magnahoteles", icon: <FaInstagram size={28} /> },
    {
      url: "https://api.whatsapp.com/send/?phone=5491167814132&text=Hola+MagnaHoteles%21+Quiero+contactarme+con+ustedes.&type=phone_number&app_absent=0",
      icon: <FaWhatsapp size={28} />,
    },
    { url: "https://linkedin.com/company/magna-hoteles", icon: <FaLinkedinIn size={28} /> },
  ];

  return <Footer links={links} socialLinks={socialLinks} />;
};

export default FooterContainer;