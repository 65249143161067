import React, { useState } from 'react';
import { FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    apellido: '',
    number: '',
    hotel: '',
    message: '',
    sendto: ''
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  // Array de opciones de hoteles
  const hotelOptions = [
    { value: '', label: 'Seleccione Hotel' },
    { value: 'Hotel Lyon', label: 'Hotel Lyon' },
    { value: 'Hotel San Miguel Plaza Spa', label: 'Hotel San Miguel Plaza Spa' },
    { value: 'Posada Sierras de Córdoba', label: 'Posada Sierras de Córdoba' },
    { value: 'Hotel Kalken', label: 'Hotel Kalken' },
    { value: 'Hotel Villa de Merlo', label: 'Hotel Villa de Merlo' },
    { value: 'Hotel Azul', label: 'Hotel Azul' },
    { value: 'Grand Hotel', label: 'Grand Hotel' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');

    fetch('https://magnahoteles.com/back/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      setResponseMessage(data.message || 'Gracias por tu contacto, en breve nos comunicaremos contigo en relación a tu solicitud.');
      if (data.status === 'success') {
        setFormData({
          name: '',
          apellido: '',
          number: '',
          hotel: '',
          message: '',
          sendto: ''
        });
      }
    })
    .catch(error => {
      setLoading(false);
      setResponseMessage('Error al enviar el mensaje.');
    });
  };

  return (
    <div className="my-6">
      <div className="grid sm:grid-cols-2 items-center gap-16 p-8 mx-auto max-w-4xl glass shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-md text-secondary font-[sans-serif]">
        <div>
          {/* Información de contacto */}
          <h1 className="text-3xl font-extrabold font-playfair">Magna Hoteles:</h1>
          <p className="text-sm text-base-200 mt-3 font-raleway font-bold">
            Si tiene alguna pregunta o inquietud, no dude en ponerse en contacto con nosotros.
          </p>
          <div className="mt-12">
            <h2 className="text-lg font-extrabold font-playfair">Email</h2>
            <ul className="mt-3">
              <li className="flex items-center">
                <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                  <FaEnvelope className="text-gray-600" />
                </div>
                <strong className="ml-2 font-montserrat">reservas@magnahoteles.com</strong>
              </li>
            </ul>
          </div>
          <div className="mt-12">
            <h2 className="text-lg font-extrabold">Socials</h2>
            <ul className="flex mt-3 space-x-4">
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://www.facebook.com/magnahotelesok">
                  <FaFacebook className="text-blue-600" />
                </a>
              </li>
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://www.instagram.com/magnahoteles/">
                  <FaInstagram className="text-pink-500" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Formulario de contacto */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input 
            type="text" 
            name="name" 
            placeholder="Nombre" 
            value={formData.name}
            onChange={handleChange}
            className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-500"
          />
          <input 
            type="text" 
            name="apellido" 
            placeholder="Apellido" 
            value={formData.apellido}
            onChange={handleChange}
            className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-500"
          />
          <input 
            type="text" 
            name="number" 
            placeholder="Número" 
            value={formData.number}
            onChange={handleChange}
            className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-500"
          />
          <input 
            type="email" 
            name="sendto" 
            placeholder="Email" 
            value={formData.sendto}
            onChange={handleChange}
            className="w-full rounded-md py-2.5 px-4 border text-sm outline-red-500"
          />
          {/* Select de Hoteles */}
          <select 
            name="hotel" 
            value={formData.hotel}
            onChange={handleChange}
            className="select select-bordered border-white w-full "
          >
            {hotelOptions.map(option => (
              <option key={option.value} value={option.value } className=''>
                {option.label}
              </option>
            ))}
          </select>
          <textarea 
            name="message" 
            placeholder="Mensaje" 
            rows="6"
            value={formData.message}
            onChange={handleChange}
            className="w-full rounded-md px-4 border text-sm pt-2.5 outline-red-500"
          ></textarea>
         
          <button 
            type="submit" 
            disabled={loading}
            className="text-white bg-red-500 hover:bg-red-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full"
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
      {responseMessage && <p className="mt-4 text-center text-red-500">{responseMessage}</p>}
    </div>
  );
};

export default ContactForm;
