import React from 'react'
import { GoGear } from "react-icons/go";
const NotFound = () => {
  return (
    <div className='min-h-screen'>
      <div className=''>
      <h1 className='text-5xl text-center font-montserrat p-20'>#404</h1>
      <br/>
      <div className='flex justify-center'><GoGear size={56} className='animate-spin animate-infinite animate-ease-in-out animate-fill-forwards' /><GoGear size={56} className=' animate-spin animate-infinite animate-alternate-reverse animate-ease-in-out animate-fill-forwards' /></div>
      <h1 className='text-2xl text-center font-montserrat'> Pagina en construccion.</h1>
      </div>
    </div>
  )
}

export default NotFound