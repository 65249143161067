// src/components/WhatsAppButton.js
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const [isBouncing, setIsBouncing] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBouncing((prev) => !prev);
    }, 1000); // Cambia el estado cada segundo

    return () => clearInterval(interval);
  }, []);

  return (
    <a
      href="https://wa.me/5491167814132?text=Hola%20MagnaHoteles!%20Quiero%20contactarme%20con%20ustedes."
      target="_blank"
      rel="noopener noreferrer"
      className={`fixed bottom-8 z-10 right-8 bg-green-500 text-white p-4 rounded-full shadow-lg transform transition-transform duration-300 ${
        isBouncing ? 'translate-y-0' : 'translate-y-2'
      }`}
      style={{ animation: 'pingpong 2s infinite' }}
    >
      <FaWhatsapp size={24} />
    </a>
  );
};

export default WhatsAppButton;
