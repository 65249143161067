import React from 'react';
import { Link } from 'react-router-dom';
import '../../../css/Footer.css';
import logo from '../../../img/logo.png'

const Footer = ({ links, socialLinks }) => {
  return (
    
    <footer className="glass text-gray-600  pb-2 pt-2 ">
      <div className="container mx-auto px-20 ">
   
      <div className="flex justify-center md:justify-center space-x-8 p-4"> 
              {socialLinks.map((social, index) => (
                <a key={index} href={social.url} className="text-accent-content hover:text-red-600">
                  {social.icon}
                </a>
              ))}
            </div>
           
          <div className="grid grid-cols-1 md:flex md:justify-around  md:grid-cols-5 gap-10 text-center  ">
          {links.map((column, index) => (
            <div key={index}>
              <h4 className="text-primary font-semibold mb-1 font-montserrat uppercase md:text-left">{column.title}</h4>
              <ul>
                {column.items.map((link, idx) => (
                  <li key={idx} className="mb-2 text-xs text-secondary text-accent-content  md:text-left font-montserrat">
                    <Link to={link.path} className="hover:text-red-500">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
    
        <div className=" border-t border-gray-200  text-gray-400 text-sm text-center ">
          &copy; 2024 Magna Hoteles. Todos los derechos reservados.
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
