import React from 'react';
import SearchBar from '../Global/SearchBar';

const Hero = ({ titleBefore, titleHighlighted, subtitle, backgroundImage, hotels }) => {
  return (
    <>
      <section
        className="relative bg-cover bg-center h-[50ch] flex flex-col items-center justify-center font-bold"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-red-600/40 via-red-100/40 to-zinc-950/10"></div>
        <div className="text-center mb-8 block z-10">
          <h1 className="text-4xl text-balance md:text-6xl font-bold animate-fade-in font-playfair from-zinc-800 via-zinc-600 to-red-600  bg-gradient-to-r bg-clip-text text-transparent drop-shadow-md ">
            {titleBefore} <span className="from-red-500 via-red-500 to-red-800 bg-gradient-to-r hover:brightness-125 bg-clip-text  text-transparent" style={{"textShadow":"1px 0px 5px rgba(255,27,27,1.5)"}}>{titleHighlighted}</span>
          </h1>
          <p className="mt-4 text-lg md:text-2xl animate-fade-in font-raleway text-black animate-pulse drop-shadow-md">
            {subtitle}
          </p>
        </div>
        <img
          className="absolute animate-fade object-cover object-center animate-fade-up animate-once animate-ease-in blur-3xl -z-40"
          src={backgroundImage}
          alt=""
        />
        {/* SearchBar positioning */}
        <div className="w-full md:w-auto px-1 sm:px-8 absolute bottom-0 static sm:mb-8">
          <SearchBar hotels={hotels} />
        </div>
      </section>
    </>
  );
};

export default Hero;
