import React from 'react';
import ServiceIcon from '../../../Global/GlobalServices/ServiceIcon'; // Asegúrate de ajustar la ruta

const ServiceCard = ({ servicios }) => {
  //console.log(servicios);
  return (
    <div className="flex justify-center space-x-4 glass p-6  shadow-lg">
      {servicios.map((servicio, index) => (
        <div key={index} className="flex flex-col items-center text-center text-white md:w-[250px] ">
          <div className="text-red-500 mb-2">
            <ServiceIcon serviceName={servicio.nombre} className="h-7 w-7 md:h-12 md:w-12" />
          </div>
          <h3 className="font-semibold md:text-lg md:block text-xs">{servicio.nombre}</h3>
          <p className="text-xs text-gray-300 md:block hidden">{servicio.descripcion}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceCard;