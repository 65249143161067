import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const hotelsMap = {
  182: 'Hotel Lyon',
  186: 'Hotel San Miguel Plaza Spa',
  189: 'Posada Sierras de Córdoba',
  184: 'Hotel Kalken',
  185: 'Hotel Villa de Merlo',
  241: 'Hotel Azul',
  183: 'Grand Hotel'
};

const SearchBar = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState('');
  const [isStartOpen, setIsStartOpen] = useState(false);  // Controla la apertura del DatePicker de "Fecha Desde"
  const [isEndOpen, setIsEndOpen] = useState(false);      // Controla la apertura del DatePicker de "Fecha Hasta"
  const navigate = useNavigate();
  


  const fechamasuno = new Date(new Date().valueOf() + 1000 * 3600 * 24);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedHotel && startDate && endDate) {
      const idDestino = selectedHotel;
      const fechaDesde = startDate.toISOString().split('T')[0].replace(/-/g, '');
      const fechaHasta = endDate.toISOString().split('T')[0].replace(/-/g, '');
      const idPortal = 9;
      const tipoDestino = 'Hotel';
      const datosMinimos = 1;
      const idHabitacion = 519;

      const url = `https://www.todoalojamiento.com/rest/api/portal/hoteles?idPortal=${idPortal}&idDestino=${idDestino}&tipoDestino=${tipoDestino}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&datosMinimos=${datosMinimos}&idHabitacion=${idHabitacion}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.success === false) {
          toast.error(data.message, {
            icon: '🚨'
          });
        } else {
          const idDestino = data.datos[0].idHotel;
          const url = `https://www.reservas.magnahoteles.com/portal/es-es?idPortal=${idPortal}&idHotel=${idDestino}&tipoDestino=${tipoDestino}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&datosMinimos=${datosMinimos}&idHabitacion=${idHabitacion}`;
          console.log('Redirecting to:', url);
          window.open(url, '_blank');
        }
      } catch (error) {
        console.error('Error fetching the API', error);
        toast.error('Error Inesperado');
      }
    }
  };

  const handleStartClick = (e) => {
    e.preventDefault();
    setIsStartOpen(!isStartOpen);
    setIsEndOpen(false);  // Cerrar el DatePicker de "Fecha Hasta" si está abierto
  };

  const handleEndClick = (e) => {
    e.preventDefault();
    setIsEndOpen(!isEndOpen);
    setIsStartOpen(false);  // Cerrar el DatePicker de "Fecha Desde" si está abierto
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setIsStartOpen(false);
    setIsEndOpen(true);  // Abrir automáticamente el DatePicker de "Fecha Hasta"
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndOpen(false);
  };

  return (
    <div className="glass rounded-lg p-2 m-0  md:p-4 shadow-lg animate-fade-in z-10 mb-5 md:m-0 relative">
      <form onSubmit={handleSubmit} className="grid grid-cols-4 lg:grid-cols-4 gap-1 md:gap-4 md:flex md:justify-center items-end" autoComplete='off'>

        {/* Select de Hotel */}
        <div className="col-span-1 text-center ">
          
         
          
          <select
            id="hotel-select"
            value={selectedHotel}
            onChange={(e) => setSelectedHotel(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg text-center appearance-none bg-base-100"
            required
          >
            <option value="">Elejí tu hotel</option>
            {Object.entries(hotelsMap).map(([id, name]) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
        </div>

        {/* Fecha Desde */}
        <div className="col-span-1 text-center ">
        
          <button 
            onClick={handleStartClick} 
            className=" w-full px-4 py-2 border border-gray-300 rounded-lg  text-center bg-base-100 truncate "
          >
            {startDate ? format(startDate, "dd-MM-yyyy") : "Check In"}
          </button>
          {isStartOpen && (
            <div className="flex items-center md:justify-evenly flex-col-reverse  ">
              <div className="absolute  flex justify-center content-center items-baseline">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  inline
                  minDate={new Date()}
                  calendarClassName="bg-white border border-gray-300 rounded-lg shadow-lg m-2"
                />
              </div>
            </div>
          )}
        </div>

        {/* Fecha Hasta */}
        <div className="col-span-1 text-center ">
          
          <button 
            onClick={handleEndClick} 
            className="w-full  px-4 py-2 border border-gray-300 rounded-lg text-center bg-base-100 truncate"
          >
            {endDate ? format(endDate, "dd-MM-yyyy") : "Check Out"}
          </button>
          {isEndOpen && (
            <div className=' flex items-center md:justify-evenly flex-col-reverse'>
              <div className="absolute  flex justify-center content-center items-baseline">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  inline
                  minDate={startDate}
                  calendarClassName="bg-white border border-gray-300 rounded-lg shadow-lg m-2 "
                />
              </div>
            </div>
          )}
        </div>

        {/* Botón Buscar */}
        <div className="col-span-1">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-red-600 text-white rounded-lg shadow hover:bg-red-700 transition font-montserrat flex justify-center"
          >
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
