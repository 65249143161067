import React from 'react';
import SearchBarHotels from '../Global/SearchBarHotels/SearchBarHotels';

const Hero = ({ title, subtitle, backgroundImage, hotel, onGalleryClick }) => {

  return (
    <>
    
    <section
      className="relative bg-cover bg-center h-[50ch] flex flex-col items-center justify-center font-bold "
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
     <div className="absolute inset-0 bg-gradient-to-t from-gray-950/60  to-zinc-950/50"></div>
       <div className="text-center mb-8  md:block z-10">
        <h1 className="text-4xl md:text-6xl font-bold animate-fade-in font-playfair text-white drop-shadow-lg">{title}</h1>
        <p className="mt-4 text-lg md:text-2xl animate-fade-in font-raleway">{subtitle}</p>
        <button className=" mt-4 md:mt-0 px-6 py-2 bg-red-600 text-white rounded-lg shadow hover:bg-red-700 transition font-montserrat " onClick={onGalleryClick}> Galeria de fotos</button>
      </div>
     <div className='w-full md:w-auto px-4 sm:px-8 absolute bottom-0 static sm:mb-8'>
      
      <SearchBarHotels hotels={hotel} />
      </div>
    </section>
    
    </>
  );
};

export default Hero;
