import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiPublicUrl, apiUrl } from '../../libs/configs/variablesEntornos';

const HotelDescription = ({ hotelId }) => {
  const [descriptions, setDescriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDescriptions = async () => {
      try {
        const response = await axios.get(`${apiUrl}/public/hotel/${hotelId}/description`);
       // console.log(response.data);
        setDescriptions(response.data);  // Guardar todas las descripciones
      } catch (error) {
        console.error('Error fetching hotel descriptions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDescriptions();
  }, [hotelId]);

  if (loading) return <p>Cargando...</p>;

  return (
    <div className="hotel-description-container ">
      {descriptions.map((description) => (
        <div
          key={description.id}
          className="hero h-full "
          style={{
            backgroundImage: `url(${apiPublicUrl}/public/${description.image_url})`, // Usar la función `url()`
            backgroundSize: 'cover', // Asegurarse de que la imagen cubra todo el área
            backgroundPosition: 'center', // Alinear la imagen al centro
          
          }}
        >
          <div className="hero-overlay  bg-opacity-20 backdrop-blur-[4px]"></div>
          <div className="hero-content text-neutral-content text-center ">
            <div className="glass bg-red-900/25 rounded-md p-5 md:mx-[200px] md:my-10">
              <h1 className="mb-5 text-3xl font-bold">{description.title}</h1>
              <p className="mb-5 md:text-md">{description.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HotelDescription;