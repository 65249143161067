import React, { useState, useEffect } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const HotelSections = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  // Agrupar las imágenes por 'descripcion' para crear los grupos
  const groupedImages = images.reduce((groups, image) => {
    const { descripcion } = image;
    if (!groups[descripcion]) {
      groups[descripcion] = [];
    }
    groups[descripcion].push(image);
    return groups;
  }, {});

  // Función para abrir el Lightbox con las imágenes del grupo seleccionado
  const openLightbox = (group, index) => {
    const formattedItems = group.map(image => ({
      src: image.url // Formateamos cada imagen del grupo para el Lightbox
    }));
    setLightboxImages(formattedItems); // Establecemos las imágenes para el Lightbox
    setStartIndex(index); // El índice de la imagen inicial en el Lightbox
    setIsOpen(true);
  };

  // Cerrar el Lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeLightbox();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <section className="">
      <div className="py-4 px-2 mx-auto sm:py-4 lg:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 h-full">
          {/* Mostrar solo una imagen principal por cada grupo (agrupado por 'descripcion') */}
          {Object.entries(groupedImages).map(([descripcion, group], index) => (
            <div
              key={index}
              className={`col-span-2 sm:col-span-1 md:col-span-${group.length > 1 ? '2' : '1'} gap-4 h-auto md:h-full flex flex-col`}
              onClick={() => openLightbox(group, 0)} // Abrir el Lightbox al hacer clic
            >
              <div className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow cursor-pointer">
                {/* Mostrar la primera imagen del grupo como imagen principal */}
                <img
                  src={group[0].url}
                  alt={descripcion}
                  className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-zinc-950/10 to-gray-950/80"></div>
                <h3 className="z-10 text-md font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-xl capitalize">
                  {descripcion} {/* Mostrar la descripción como título */}
                </h3>
              </div>
              {group.length > 1 && (
                <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                  {/* Si hay subitems, mostrarlos debajo */}
                  {group.slice(1).map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      onClick={() => openLightbox(group, subIndex + 1)} // Abrir el Lightbox en la imagen seleccionada
                      className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 cursor-pointer"
                    >
                      <img
                        src={subItem.url}
                        alt={subItem.descripcion}
                        className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                      />
                      <div className="absolute inset-0 bg-gradient-to-b from-zinc-950/10 to-gray-950/80"></div>
                      <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                        {/* {subItem.descripcion} */}
                      </h3>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Lightbox */}
        {isOpen && (
          <Lightbox
            open={isOpen}
            close={closeLightbox}
            slides={lightboxImages}
            index={startIndex}
            onPrev={() => setStartIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0))}
            onNext={() => setStartIndex((prevIndex) => (prevIndex < lightboxImages.length - 1 ? prevIndex + 1 : prevIndex))}
          />
        )}
      </div>
    </section>
  );
};

export default HotelSections;