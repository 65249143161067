import React from 'react';
import About from '../Components/About/About';


const AboutPage = () => {
  return (
    <div className=" min-h-screen p-5">
      <About />
    </div>
  );
};

export default AboutPage;
