import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const AnimatedComponent = ({ children, animationType, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const animations = {
    fadeInUp: { initial: { opacity: 0, y: 50 }, animate: { opacity: 1, y: 0 } },
    fadeInDown: { initial: { opacity: 0, y: -50 }, animate: { opacity: 1, y: 0 } },
    fadeInLeft: { initial: { opacity: 0, x: -100  }, animate: { opacity: 1, x: 0 } },
    fadeInRight: { initial: { opacity: 0, x: 100  }, animate: { opacity: 1, x: 0 } },
    zoomIn: { initial: { opacity: 0, scale: 0.8 }, animate: { opacity: 1, scale: 1 } },
    zoomOut: { initial: { opacity: 0, scale: 1.2 }, animate: { opacity: 1, scale: 1 } },
    rotate: { initial: { opacity: 0, rotate: -45 }, animate: { opacity: 1, rotate: 0 } },
    slideInRight: { initial: { x: '100%' }, animate: { x: 0 } },
    slideInLeft: { initial: { x: '-100%' }, animate: { x: 0 } },
  };

  const selectedAnimation = animations[animationType] || animations.fadeInUp;

  return (
    <motion.div
      ref={ref}
      initial={selectedAnimation.initial}
      animate={inView ? selectedAnimation.animate : selectedAnimation.initial}
      transition={{ duration: 0.9, delay: inView ? delay : 0.9 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedComponent;
