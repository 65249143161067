import React from 'react'
import ContactForm from '../Components/Contact/ContactForm'

const ContactPage = () => {
  return (
    <div className=" min-h-screen p-5"><ContactForm/></div>
  )
}

export default ContactPage

