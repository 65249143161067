import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaHotel, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const MainMenu = ({ menuItems }) => {
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const subMenuRef = useRef(null);

  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex(openSubMenuIndex === index ? null : index);
  };

  const closeSubMenu = () => {
    setOpenSubMenuIndex(null);
  };

  const handleClickOutside = (event) => {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
      closeSubMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const icons = {
    home: <FaHome />,
    nosotros: <FaInfoCircle />,
    hoteles: <FaHotel />,
    contacto: <FaEnvelope />,
    facebook: <FaFacebook />,
    instagram: <FaInstagram />,
    linkedin: <FaLinkedin />
  };

  return (
    <ul className="hidden md:flex space-x-6 font-montserrat font-semibold items-center">
      {menuItems.map((item, index) => (
        <li key={item.label} className="relative group">
          {item.submenu ? (
            <>
              <button
                onClick={() => toggleSubMenu(index)}
                className="flex items-center focus:outline-none text-base-200 hover:text-red-500 transition duration-300"
              >
                <span className="mr-2 group-hover:animate-bounce">{icons[item.icon]}</span>
                <span>{item.label.toUpperCase()}</span>
              </button>
              {openSubMenuIndex === index && (
                <ul
                  ref={subMenuRef}
                  className="absolute left-0 z-[20] mt-2 w-48 glass border border-gray-200 rounded-md shadow-lg"
                >
                  {item.submenu.map((subItem) => (
                    <li key={subItem.label}>
                      <Link
                        to={subItem.path}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-950 rounded-md hover:text-red-500"
                        onClick={closeSubMenu}
                      >
                        {subItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : item.external ? (
            // Enlace externo para redes sociales
            <a
              href={item.path}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center text-base-200 hover:text-red-500 transition duration-300"
              style={{ minWidth: '10px' }} // Ajuste para dar ancho mínimo a los íconos de redes sociales
            >
              <span className="group-hover:animate-bounce">{icons[item.icon]}</span>
            </a>
          ) : (
            // Enlace interno para navegación
            <Link
              to={item.path}
              className="flex items-center text-base-200 hover:text-red-500 transition duration-300"
            >
              <span className="mr-2 group-hover:animate-bounce">{icons[item.icon]}</span>
              <span>{item.label.toUpperCase()}</span>
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MainMenu;
