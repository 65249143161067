import React from 'react';
import { Outlet} from 'react-router-dom';
import Navbar from '../Components/Global/NavBar/Navbar';
import FooterContainer from '../Components/Global/Footer/FooterContainer';
import { Toaster } from 'react-hot-toast';
import WhatsAppButton from '../Components/WhatsAppButton';

const Layout = () => {

  return (
    <div className="min-h-screen">
      <header>
       <Navbar/>
      </header>
      <main className="[background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#3b3b3b_100%)] md:[background:none]">
        <Outlet />
      </main>
      <WhatsAppButton/>
      <FooterContainer />
      <Toaster
  position="top-right"
  reverseOrder={false}
  gutter={8}
  containerClassName=""
  containerStyle={{}}
  toastOptions={{
    // Define default options
    className: '',
    duration: 5000,
    style: {
      background: '#363636',
      color: '#fff',
    },

    // Default options for specific types
    success: {
      duration: 3000,
      theme: {
        primary: 'green',
        secondary: 'black', 
      },
    },
  }}
/>
    </div>
   
  );
}

export default Layout;