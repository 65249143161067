import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ServiceIcon from '../Global/GlobalServices/ServiceIcon'
const BookingCard = ({ image, name, rating, description, idHotel, services, hotel }) => {

  // Función para renderizar las estrellas según el rating
  const renderStars = (rating) => {
    return Array.from({ length: rating }, (_, index) => (
      <FaStar key={index} className="-mt-0.5 h-5 w-5 text-amber-500 " />
    ));
  };
 
  return (
    <div className="relative flex flex-col h-full w-full max-w-[26rem] rounded-xl glass bg-zinc-950/80 bg-clip-border text-gray-700 shadow-lg transition-transform transform hover:shadow-2xl hover:brightness-125 hover:animate-pulse animate-twice animate-duration-[2500ms] animate-delay-[200ms] animate-ease-in animate-reverse animate-fill-both">
      <div className="relative mx-4 mt-4 h-[15ch] md:h-[25ch] overflow-hidden bg-cover bg-center rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40">
        <img src={image} alt={name} className="object-cover h-full w-full" />
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
      </div>
      <div className="flex flex-col flex-1 p-6 items-center text-center">
        <div className="flex justify-center mb-3 gap-1.5">
          {renderStars(rating)} {/* Renderizar estrellas dinámicamente */}
        </div>
        <h5 className="block font-sans text-lg md:text-xl font-medium leading-snug tracking-normal text-secondary antialiased font-montserrat mb-2">
          {name}
        </h5>
        <p className="block font-sans text-sm md:text-base font-light leading-relaxed text-base-300 antialiased overflow-ellipsis overflow-hidden mb-4 text-ellipsis">
          {description}
        </p>
        
        {/* Renderizar los servicios dinámicamente */}
        <div className="mt-5 flex-grow flex items-end">
          <div className="flex md:flex-nowrap flex-wrap gap-2 justify-center ">
            {services.map((service, index) => (
              <Link to={`/hoteles/${hotel.nombre.toLowerCase().replace(/\s+/g, "-")}/${hotel.id}/#section-2`} key={index}>
                <span className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-1 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 tooltip tooltip-primary" data-tip={service.nombre}>
                  <ServiceIcon serviceName={service.nombre} />
                </span>
              </Link>
            ))}
          </div>
        </div>
        
        <div className="mt-4 w-full">
          <Link
            className="block w-full select-none rounded-lg bg-red-600 py-3.5 px-7 text-center align-middle font-sans text-sm font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button"
            data-ripple-light="true"
            to={`/hoteles/${hotel.nombre.toLowerCase().replace(/\s+/g, "-")}/${hotel.id}/`}
          >
            Ver más
          </Link>
        </div>
      </div>
    </div>
  );
};


const BookingCardLoader = ({ image, name, rating, description, features }) => {

  return (
    <div className="relative flex w-full max-w-[26rem] flex-col rounded-xl glass bg-clip-border text-gray-700 shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl animate-pulse ">
      <div className="relative mx-4 mt-4 h-[15ch] md:h-[25ch] overflow-hidden bg-cover bg-center rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-900/90">
        
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-gray-500/30  to-gray-500/60"></div>
        <button
          className="!absolute top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase text-red-500 transition-all hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-dark="true"
        >
          <span className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-6 w-6">
              <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"></path>
            </svg>
          </span>
        </button>
      </div>
      <div className="p-6">
        <div className="mb-3 flex items-center justify-between">
          <div className="block h-4 bg-gray-300 w-full font-sans text-lg md:text-xl font-medium leading-snug tracking-normal text-blue-gray-900 antialiased font-montserrat ">
            
          </div>
          
          <p className="flex items-center gap-1.5 font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
            <FaStar className="-mt-0.5 h-5 w-5 text-yellow-500" />
            {rating}
          </p>
        </div>
        <p className="block mb-2 h-4 bg-gray-300 w-full font-sans text-sm md:text-base font-light leading-relaxed text-gray-700 antialiased overflow-ellipsis overflow-hidden  ">
        </p>
        <p className="block h-4 mb-8 bg-gray-300 w-full font-sans text-sm md:text-base font-light leading-relaxed text-gray-700 antialiased overflow-ellipsis overflow-hidden  ">
        </p>
        <p className="block h-3 mb-2 bg-gray-300 w-full font-sans text-sm md:text-base font-light leading-relaxed text-gray-700 antialiased overflow-ellipsis overflow-hidden  ">
        </p>
        <p className="block h-3 mb-1 bg-gray-300 w-full font-sans text-sm md:text-base font-light leading-relaxed text-gray-700 antialiased overflow-ellipsis overflow-hidden  ">
        </p>
        <p className="block h-3 mb-1 bg-gray-300 w-full font-sans text-sm md:text-base font-light leading-relaxed text-gray-700 antialiased overflow-ellipsis overflow-hidden  ">
        </p>
        <div className="group mt-8 inline-flex flex-wrap items-center gap-1 ">
          
            <span
              className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-3 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 h-10 w-10">  </span>
            <span className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-3 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 h-10 w-10"> </span>
        <span className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-3 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 h-10 w-10">  </span>
        <span className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-3 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 h-10 w-10"> </span>
        <span className="cursor-pointer rounded-full border border-red-500/5 bg-red-500/5 p-3 text-red-500 transition-colors hover:border-red-500/10 hover:bg-red-500/10 hover:!opacity-100 group-hover:opacity-70 h-10 w-10">  </span>
        </div>
      </div>
      <div className="p-6 pt-3">
        <button
          className="block w-full select-none rounded-lg bg-red-600 h-[50px] py-3.5 px-7 text-center align-middle font-sans text-sm font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          data-ripple-light="true"
        >
          
        </button>
      </div>
    </div>
  );
};

export {BookingCard, BookingCardLoader};
