import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaHotel, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoCloseOutline } from "react-icons/io5";

const SideMenu = ({ menuItems, isMenuOpen, toggleMenu, isSubMenuOpen, toggleSubMenu }) => {
  const icons = {
    home: <FaHome />,
    nosotros: <FaInfoCircle />,
    hoteles: <FaHotel />,
    contacto: <FaEnvelope />,
    facebook: <FaFacebook />,
    instagram: <FaInstagram />,
    linkedin: <FaLinkedin />
  };

  return (
    <>
      {/* Fondo Oscuro */}
      <div className={`fixed inset-0 bg-black bg-opacity-60 z-20 transition-opacity ${isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleMenu}></div>
      {/* Menú Lateral */}
      <div className={`fixed top-0 left-0 h-full glass shadow-md w-1/2 z-30 transform transition-transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="p-4">
          <button onClick={toggleMenu} className="text-gray-100 focus:outline-none">
            <IoCloseOutline className="w-8 h-8" />
          </button>
        </div>
        <ul className="flex flex-col space-y-4 p-4">
          {menuItems.map((item) => (
            <React.Fragment key={item.label}>
              <li className="relative">
                {item.submenu ? (
                  <>
                    {/* Botón de menú con submenú */}
                    <button onClick={toggleSubMenu} className="text-gray-300 hover:text-red-500 flex items-center focus:outline-none font-montserrat font-bold">
                      {icons[item.icon]} <span className="ml-2">{item.label.toUpperCase()}</span>
                    </button>
                    {/* Submenú */}
                    <ul className={`mt-2 w-full glass border border-gray-200 rounded-md shadow-lg ${isSubMenuOpen ? 'block' : 'hidden'}`}>
                      {item.submenu.map((subItem) => (
                        <li key={subItem.label}>
                          <Link to={subItem.path} className="block px-4 py-2 text-gray-200 font-montserrat font-bold hover:bg-gray-950 rounded-md hover:text-red-500" onClick={toggleMenu}>{subItem.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  // Enlace del menú sin submenú
                  <Link to={item.path} className="text-gray-300 hover:text-red-500 flex items-center font-montserrat font-bold" onClick={toggleMenu}>
                    {icons[item.icon]} <span className="ml-2">{item.label.toUpperCase()}</span>
                  </Link>
                )}
              </li>

              {/* Añadir un divisor usando DaisyUI después del elemento "hoteles" */}
              {item.label === 'hoteles' && <div className="divider my-2"></div>}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SideMenu;
