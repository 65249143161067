import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainMenu from './MainMenu';
import SideMenu from './SideMenu';
import { FaBars } from 'react-icons/fa';
import useWindowSize from '../../../hooks/useWindowSize';
import logo from '../../../img/isologo.png'
import { apiUrl } from '../../../libs/configs/variablesEntornos';
// const menuItems = [
//   { label: 'home', path: '/', icon: 'home' },
//   { label: 'nosotros', path: '/nosotros', icon: 'nosotros' },
//   {
//     label: 'hoteles', path: '/hoteles', icon: 'hoteles', submenu: [
//       { label: 'San Miguel Plaza', path: '/hoteles/186' },
//       { label: 'Villa de Merlo Spa', path: '/hoteles/185' },
//       { label: 'Hotel Kalken', path: '/hoteles/184' },
//       { label: 'Hotel Lyon', path: '/hoteles/182' },
//       { label: 'Hotel Azul', path: '/hoteles/241' },
//       { label: 'Grand Hotel', path: '/hoteles/183' },
//       { label: 'Posada Sierras de Cordoba', path: '/hoteles/189' },
//     ]
//   },
//   { label: 'facebook', path: 'https://www.facebook.com/magnahotelesok', icon: 'facebook', external: true },
//   { label: 'instagram', path: 'https://www.instagram.com/magnahoteles/', icon: 'instagram', external: true },
//   { label: 'linkedin', path: 'https://www.linkedin.com/company/magna-hoteles/', icon: 'linkedin', external: true },
// ];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [newHotels, setNewHotels] = useState([]);
  const size = useWindowSize();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleSubMenu = () => setIsSubMenuOpen(!isSubMenuOpen);



  useEffect(() => {
    const fetchHotels = async () => {
      const url = `${apiUrl}/public/hotels`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();

        // Transformar datos para agrupar hoteles por ID
        const hotelMap = new Map();
      //  console.log(data)
        data.forEach((hotel) => {

          if (!hotelMap.has(hotel.idHotel)) {
            hotelMap.set(hotel.idHotel, {
              label: hotel.nombre,
              path: `/hoteles/${hotel.nombre.toLowerCase().replace(/\s+/g, "-")}/${hotel.id}`,
            });
          }
        });

        setNewHotels(Array.from(hotelMap.values()));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchHotels();
  }, []);

  // Definir los ítems del menú con los hoteles dinámicos
  const menuItems = [
    { label: 'home', path: '/', icon: 'home' },
    { label: 'nosotros', path: '/nosotros', icon: 'nosotros' },
    {
      label: 'hoteles',
      path: '/hoteles',
      icon: 'hoteles',
      submenu: newHotels // Aquí reemplazamos el submenu con los hoteles obtenidos dinámicamente
    },
    { label: 'facebook', path: 'https://www.facebook.com/magnahotelesok', icon: 'facebook', external: true },
    { label: 'instagram', path: 'https://www.instagram.com/magnahoteles/', icon: 'instagram', external: true },
    { label: 'linkedin', path: 'https://www.linkedin.com/company/magna-hoteles/', icon: 'linkedin', external: true },
  ];

  return (
    <nav className="shadow-2xl bg-zinc-900 md:bg-null  w-full  fixed z-[20]">
      <div className="container mx-auto px-4  py-2 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold"><img src={logo} /></Link>
        {size.width >= 768 ? (
          <MainMenu menuItems={menuItems} toggleSubMenu={toggleSubMenu} isSubMenuOpen={isSubMenuOpen} />
        ) : (
          <button onClick={toggleMenu} className="text-gray-100 focus:outline-none ">
            <FaBars className="w-6 h-6" />
          </button>
        )}
      </div>
      {size.width < 768 && (
        <SideMenu menuItems={menuItems} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} isSubMenuOpen={isSubMenuOpen} toggleSubMenu={toggleSubMenu} />
      )}
    </nav>
  );
};

export default Navbar;
