import React, {useEffect, useState} from 'react';
import {BookingCard, BookingCardLoader} from './BookingCard';
import {apiPublicUrl, apiUrl} from '../../libs/configs/variablesEntornos';

const HotelsSection = (Hotels) => {
  const [loading, setLoading] = useState(true);
  //console.log(Hotels)

  useEffect(() => {
    if (Hotels.data ) {
      setLoading(false);
    }
  }, [Hotels]);
  return (
    <section className="py-16 m-2 ">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 ">Nuestros Hoteles</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {loading ? ( 
            <>
            <BookingCardLoader />
            <BookingCardLoader />
            <BookingCardLoader />
            <BookingCardLoader />
            <BookingCardLoader /> 
            </>
            ) : (
             <>
              {Hotels.data.map((hotel, index) => (
                <BookingCard 
                  key={index} 
                  image={`${apiPublicUrl}${hotel.imagen_default}`} 
                  name={hotel.nombre} 
                  rating={hotel.estrellas} 
                  description={hotel.descripcion}
                  idHotel={hotel.idHotel}
                  hotel={hotel}
                  services={hotel.servicios}
                />
              ))}
              </>
          )}
          
         
        </div>
      </div>
    </section>
  );
}

export default HotelsSection;
