import React, {useState, useEffect} from 'react';
import HotelList from '../../Components/Hotels/HotelList';
import HotelsSection from '../../Components/BookingCard/HotelSection';
import { apiUrl } from '../../libs/configs/variablesEntornos';
const HotelCatalog = () => {
  const [newHotels, setNewHotels] = useState([]);

  useEffect(() => {
    const fetchDataa = async () => {
      const url = `${apiUrl}/public/hotels`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        const data = await response.json();
  
        setNewHotels(data);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataa();
  }, []); // Elimina 'newHotels' de la lista de dependencias



  return (
    <div className="container mx-auto px-4 py-8">
      <HotelsSection data={newHotels}/>
    </div>
  );
};

export default HotelCatalog;
