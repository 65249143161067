import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../css/OffersSlider.css'

// Import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { formatearNumeroConComas, formatearNumeroConPuntos } from '../../utils/tools';

const OffersSlider = ({ offers }) => {

  const filteredOffers = offers?.datos?.filter(offer => offer.preciosEconomicos?.precioXNoche > 0);

  const handleOnClick = (offer) => {
    const idHotel = offer.idHotel; // Supongo que hay un campo idDestino en la oferta
    const fechaDesde = new Date().toISOString().split('T')[0].replace(/-/g, ''); // Obtiene la fecha actual en formato yyyyMMdd
    const fechaHasta = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0].replace(/-/g, ''); // 7 días después

    const url = `https://www.reservas.magnahoteles.com/portal/es-es?idPortal=9&idDestino=1&idHotel=${idHotel}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;

    console.log(url);
    window.open(url, '_blank'); // Abre la URL en una nueva pestaña
  };

  return (
    <div className="offers-slider-container py-10">
      <h2 className="text-3xl font-bold text-center mb-8 mt-2 font-montserrat">Ofertas exclusivas</h2>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: true,
        }}
        className="mySwiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {filteredOffers?.map((offer, index) => (
          <SwiperSlide key={index}>
            <div className="card glass bg-zinc-950/90 shadow-lg rounded-lg overflow-hidden hover:brightness-125">
              <div className="relative">
                <img src={offer.imagenPorDefecto} alt={offer.nombre} className="w-full h-40 object-cover" />
                <div className="absolute top-0 right-0 bg-red-700 text-white px-2 py-1 rounded-bl-lg">
                  {offer.preciosEconomicos.descEco}%
                </div>
              </div>
              <div className="p-4 flex flex-col justify-center content-center items-center ">
                <h3 className="text-xl text-secondary font-semibold mb-1">{offer.nombre}</h3>
                <p className="text-base-200 mb-4">{offer.direccion}</p>
                <div className="flex items-center mb-2">
                  <p className="text-primary text-sm mr-2">Precio por Noche:</p>
                </div>
                <p className="text-base-300 font-bold text-lg mb-4">${formatearNumeroConPuntos(offer.preciosEconomicos.precioXNoche.toFixed())}</p>
                <button 
                  className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300" 
                  onClick={() => handleOnClick(offer)} // Pasa la oferta actual a la función handleOnClick
                >
                  VER OFERTA
                </button>
                <p className="text-gray-500 text-sm mt-2">Aplica términos y condiciones.</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default OffersSlider;
