import React from 'react';
import AnimatedComponent from '../AnimatedComponent';
import '../../css/About.css';

const About = () => {
  return (
    <section className="glass rounded-md py-16 ">
      <div className="container mx-auto px-4">
        <div className="xl:w-[90%] sm:w-[85%] xs:w-[90%] mx-auto md:flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6 md:pt-0 pt-4">
          <AnimatedComponent animationType="zoomIn" delay={0.2}>
            <div className="about-image">
              <img
                src="images/hotel-lyon.webp"
                alt="About Us"
                className="rounded-lg shadow-lg object-contain "
              />
            </div>
          </AnimatedComponent>
          <AnimatedComponent animationType="fadeInUp" delay={0.2}>
            <div className='md:block sm:hidden xs:hidden  xs:w-full md:pt-0 pt-4'>
              <h2 className="text-3xl font-bold text-base-300 mb-4">Sobre Nosotros</h2>
              <p className="text-base-200 mb-4">
                En Magna Hoteles, nos esforzamos por ofrecer una experiencia inolvidable a nuestros huéspedes. Desde el lujo de nuestras instalaciones hasta la calidez de nuestro servicio, cada detalle está cuidadosamente pensado para brindar comodidad y satisfacción.
              </p>
              <p className="text-base-200 mb-4">
                Con ubicaciones en los destinos más exóticos y deseables del mundo, nuestros hoteles ofrecen una variedad de servicios y actividades diseñadas para todos los gustos. Ya sea que busques una escapada relajante o una aventura emocionante, Magna Hoteles tiene algo para ti.
              </p>
              <p className="text-base-200 mb-4">
                Únete a nosotros y descubre por qué somos uno de los nombres más confiables en la industria hotelera.
              </p>
            </div>
          </AnimatedComponent>
        </div>
        <div className="xl:w-[90%] sm:w-[85%] xs:w-[90%] mx-auto md:flex md:flex-row-reverse xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6 md:pt-0 pt-4">
          <AnimatedComponent animationType="fadeInDown" delay={0.4}>
          <div className="md:block sm:hidden xs:hidden  xs:w-full" >
              <img
                src="images/hotel-kalken.jpg"
                alt="Our History"
                className="rounded-xl mx-auto object-contain xs:rounded-sm hover:shadow-xl hover:contrast-125"
              />
            </div>
            
          </AnimatedComponent>
          <AnimatedComponent animationType="zoomIn" delay={0.4}>
          <div className='md:block sm:hidden xs:hidden  xs:w-full md:pt-0 pt-4'>
              <h2 className="text-3xl font-bold text-base-300 mb-4">Nuestra Historia</h2>
              <p className="text-base-200 mb-4">
                Magna Hoteles comenzó con una visión clara: ofrecer lujo y confort en los destinos más hermosos del mundo. Desde nuestros humildes comienzos, hemos crecido para incluir una amplia gama de hoteles y resorts que mantienen nuestros altos estándares de calidad.
              </p>
              <p className="text-base-200 mb-4">
                A lo largo de los años, hemos recibido numerosos premios y reconocimientos por nuestro compromiso con la excelencia en el servicio y la satisfacción del cliente. Nuestro equipo de profesionales está dedicado a hacer que cada estancia sea memorable.
              </p>
            </div>
          </AnimatedComponent>
        </div>
        <div className="xl:w-[90%] sm:w-[85%] xs:w-[90%] mx-auto md:flex md:flex-row xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6 md:pt-0 pt-4">
          <AnimatedComponent animationType="zoomIn" delay={0.2}>
            <div className="about-image">
              <img
                src="images/hotel-azul.jpg"
                alt="About Us"
                className="rounded-lg shadow-lg object-contain "
              />
            </div>
          </AnimatedComponent>
          <AnimatedComponent animationType="fadeInUp" delay={0.2}>
            <div className='md:block sm:hidden xs:hidden  xs:w-full md:pt-0 pt-4'>
              <h2 className="text-3xl font-bold text-base-300 mb-4">Responsabilidad Social Empresarial</h2>
              <p className="text-base-200 mb-4">
                En Magna Hoteles, nos esforzamos por ofrecer una experiencia inolvidable a nuestros huéspedes. Desde el lujo de nuestras instalaciones hasta la calidez de nuestro servicio, cada detalle está cuidadosamente pensado para brindar comodidad y satisfacción.
              </p>
              <p className="text-base-200 mb-4">
                Con ubicaciones en los destinos más exóticos y deseables del mundo, nuestros hoteles ofrecen una variedad de servicios y actividades diseñadas para todos los gustos. Ya sea que busques una escapada relajante o una aventura emocionante, Magna Hoteles tiene algo para ti.
              </p>
              <p className="text-base-200 mb-4">
                Únete a nosotros y descubre por qué somos uno de los nombres más confiables en la industria hotelera.
              </p>
            </div>
          </AnimatedComponent>
        </div>
        <div className="xl:w-[90%] sm:w-[85%] xs:w-[90%] mx-auto md:flex md:flex-row-reverse xs:flex-col lg:gap-4 xs:gap-2 justify-center lg:items-stretch md:items-center mt-6 md:pt-0 pt-4">
          <AnimatedComponent animationType="fadeInDown" delay={0.4}>
          <div className="md:block sm:hidden xs:hidden  xs:w-full" >
              <img
                src="images/frente.jpg"
                alt="Our History"
                className="rounded-xl mx-auto object-contain xs:rounded-sm hover:shadow-xl hover:contrast-125"
              />
            </div>
            
          </AnimatedComponent>
          <AnimatedComponent animationType="zoomIn" delay={0.4}>
          <div className='md:block sm:hidden xs:hidden  xs:w-full md:pt-0 pt-4'>
              <h2 className="text-3xl font-bold text-base-300 mb-4">Nuestro Equipo</h2>
              <p className="text-base-200 mb-4">
                Magna Hoteles comenzó con una visión clara: ofrecer lujo y confort en los destinos más hermosos del mundo. Desde nuestros humildes comienzos, hemos crecido para incluir una amplia gama de hoteles y resorts que mantienen nuestros altos estándares de calidad.
              </p>
              <p className="text-base-200 mb-4">
                A lo largo de los años, hemos recibido numerosos premios y reconocimientos por nuestro compromiso con la excelencia en el servicio y la satisfacción del cliente. Nuestro equipo de profesionales está dedicado a hacer que cada estancia sea memorable.
              </p>
            </div>
          </AnimatedComponent>
        </div>
      </div>
    </section>
  );
};

export default About;
