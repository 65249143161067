import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ hotels }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(hotels || '');
  const [isStartOpen, setIsStartOpen] = useState(false);  // Controla la apertura del DatePicker de "Fecha Desde"
  const [isEndOpen, setIsEndOpen] = useState(false);      // Controla la apertura del DatePicker de "Fecha Hasta"
  const navigate = useNavigate();

  const fechamasuno = new Date(new Date().valueOf() + 1000 * 3600 * 24);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedHotel && startDate && endDate) {
      const idDestino = selectedHotel;
      const fechaDesde = startDate.toISOString().split('T')[0].replace(/-/g, '');
      const fechaHasta = endDate.toISOString().split('T')[0].replace(/-/g, '');
      const idPortal = 9;
      const tipoDestino = 'Hotel';
      const datosMinimos = 1;
      const idHabitacion = 519;
      
      const url = `https://www.reservas.magnahoteles.com/portal/es-es?idPortal=${idPortal}&idHotel=${idDestino}&tipoDestino=${tipoDestino}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}&datosMinimos=${datosMinimos}&idHabitacion=${idHabitacion}`;
      
      console.log('Redirecting to:', url);
      window.open(url, '_blank');
    }
  };

  const handleStartClick = (e) => {
    e.preventDefault();
    setIsStartOpen(!isStartOpen);
    setIsEndOpen(false);  // Cerrar el DatePicker de "Fecha Hasta" si está abierto
  };

  const handleEndClick = (e) => {
    e.preventDefault();
    setIsEndOpen(!isEndOpen);
    setIsStartOpen(false);  // Cerrar el DatePicker de "Fecha Desde" si está abierto
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setIsStartOpen(false);
    setIsEndOpen(true);  // Abrir automáticamente el DatePicker de "Fecha Hasta"
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndOpen(false);
  };

  return (
    <div className="glass rounded-lg p-3 md:p-4 shadow-lg animate-fade-in z-10 mb-5 md:m-0 relative">
      <form onSubmit={handleSubmit} className="grid grid-cols-3 items-end gap-1 justify-end md:flex " autoComplete='off'>
        

          {/* Fecha Desde */}
          <div className="col-span-1">
            <label className="text-gray-100 mb-2 md:text-md text-xs" htmlFor="check-in">
              Check In
            </label>
            <button 
              onClick={handleStartClick} 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-center bg-white truncate"
            >
              {startDate ? format(startDate, "dd-MM-yyyy") : "Seleccionar fecha"}
            </button>
            {isStartOpen && (
              <div className='flex items-center md:justify-evenly flex-col-reverse ml-20'>
              <div className="absolute  ml-20 flex justify-center content-center items-baseline">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  inline
                  
                  calendarClassName="bg-white border border-gray-300 rounded-lg shadow-lg p-2"
                />
              </div>
              </div>
            )}
          </div>

          {/* Fecha Hasta */}
          <div className="col-span-1">
            <label className="text-gray-100 mb-2 md:text-md text-xs" htmlFor="check-out">
              Check Out
            </label>
            <button 
              onClick={handleEndClick} 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-center bg-white truncate"
            >
              {endDate ? format(endDate, "dd-MM-yyyy") : "Seleccionar fecha"}
            </button>
            {isEndOpen && (
              <div className='flex items-center md:justify-evenly flex-col-reverse ml-20'>
              <div className="absolute  mr-20 flex justify-center content-center items-baseline">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  inline
                  minDate={fechamasuno}
                  calendarClassName="bg-white border border-gray-300 rounded-lg shadow-lg p-2"
                />
              </div>
              </div>
            )}
          </div>
        

        {/* Botón Buscar */}
        <div className='col-span-1'>
        <button
          type="submit"
          className="mt-4 md:mt-0 px-6 py-2 bg-red-600 text-white rounded-lg shadow hover:bg-red-700 transition font-montserrat"
        >
          Buscar
        </button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
