// SmoothScroll.js

const smoothScrollTo = (targetElement, duration) => {
    const startPosition = window.pageYOffset;
    const targetPosition = targetElement.getBoundingClientRect().top;
    const startTime = performance.now();
  
    const easeInOutQuad = (t) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };
  
    const scrollAnimation = (currentTime) => {
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const ease = easeInOutQuad(progress);
  
      window.scrollTo(0, startPosition + targetPosition * ease);
  
      if (timeElapsed < duration) {
        requestAnimationFrame(scrollAnimation);
      }
    };
  
    requestAnimationFrame(scrollAnimation);
  };
  
  export default smoothScrollTo;
  