import { Routes, Route } from "react-router-dom";
import Static from "./Layouts/static";
import Home from "./Pages/Home";
import AboutPage from "./Pages/About";
import HotelCatalog from "./Pages/Hotels/HotelCatalog";
import HotelDetail from "./Pages/Hotels/HotelDetail"; // Nuevo componente dinámico
import NotFound from "./Pages/NotFound";
import Arrepentimientodecompra from "./Pages/Arrepentimientodecompra";
import ScrollToTop from "./hooks/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Static />}>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<AboutPage />} />
          <Route path="/hoteles" element={<HotelCatalog />} />
          {/* Ruta dinámica para hoteles */}
          <Route path="/hoteles/:slug/:idHotel" element={<HotelDetail />} />
          <Route path="/arrepentimientodecompra" element={<Arrepentimientodecompra />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;