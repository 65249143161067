import React from 'react';
import { FaWifi, FaBed, FaTv, FaSwimmer, FaConciergeBell, FaBusinessTime, FaShuttleVan, FaChair, FaBaby } from 'react-icons/fa';
import { FaSpa } from "react-icons/fa6";
import { MdFreeBreakfast } from "react-icons/md";
import { GiCoffeeCup, GiMeal, GiClothes, GiWineGlass } from 'react-icons/gi';
import { CgGym } from "react-icons/cg";
import { IoMdHappy } from 'react-icons/io';

// Mapeo de nombres de servicios a íconos
const serviceIcons = {
  'Wifi': FaWifi,
  'Spa': FaSpa,
  'Conserje': FaConciergeBell,
  'GYM': CgGym,
  'Restaurante': GiMeal,
  'Piscina': FaSwimmer,
  'Servicio de habitaciones': FaBed,
  'Centro de negocios': FaBusinessTime,
  'Transporte al aeropuerto': FaShuttleVan,
  'Salas de reuniones': FaChair,
  'Desayuno': MdFreeBreakfast,
  'Conserjería': FaConciergeBell,
  'Servicio de lavandería': GiClothes,
  'Bar en la terraza': GiWineGlass,
  'Guardería infantil': FaBaby,
  'Actividades recreativas': IoMdHappy,  // Puedes personalizar este ícono con algo más representativo
};

// Componente que recibe el nombre del servicio y renderiza el ícono
const ServiceIcon = ({ serviceName, ...props }) => {
  const IconComponent = serviceIcons[serviceName] || IoMdHappy; // Ícono por defecto si no se encuentra el servicio
  return <IconComponent {...props} />;
};

export default ServiceIcon;