import React, {useState, useEffect} from 'react';

import Hero from '../Components/Global/Hero';
import HotelsSection from '../Components/BookingCard/HotelSection';
import AnimatedComponent from '../Components/AnimatedComponent';
import OffersSlider from '../Components/Offers/OffersSlider';
import { Divider } from '../Components/Divider';
import { apiUrl } from '../libs/configs/variablesEntornos';


const Home = () => {
  const fechaDesde = new Date().toISOString().split('T')[0].replace(/-/g, '');
  const fechaHasta = new Date((new Date()).valueOf() + 1000*3600*24).toISOString().split('T')[0].replace(/-/g, '');
  const [Hotels, setHotels] = useState ([]);
  const [newHotels, setNewHotels] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      const url = `https://todoalojamiento.com/rest/api/portal/hoteles?idPortal=9&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`;
        //const url = 'http://192.168.1.46:3001/api/public/hotels'
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
 
        const data = await response.json();
       
        
        // Modificar el array de hoteles dentro de data.datos
        const modifiedHotelsArray = data.datos.map(hotel => ({
          ...hotel,
          imagenPorDefecto: hotel.idHotel === 182 
            ? '/images/hotel-lyon.webp'
            : hotel.imagenPorDefecto
        }));
  
        // Rearmar el objeto completo incluyendo la modificación en data.datos
        const modifiedData = {
          ...data,
          datos: modifiedHotelsArray
        };
  
        setHotels(modifiedData);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [fechaDesde, fechaHasta]);

  useEffect(() => {
    const fetchDataa = async () => {
      const url = `${apiUrl}/public/hotels`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
  
        const data = await response.json();
  
        setNewHotels(data);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataa();
  }, []); // Elimina 'newHotels' de la lista de dependencias

  return (
    <div className="min-h-screen font-montserrat">
      {/* HERO CONTAINER + BUSCADOR. */}
      <Hero 
  titleBefore="Bienvenido a" 
  titleHighlighted="Magna Hoteles" 
  subtitle="Tu próxima experiencia" 
  backgroundImage="/images/camados.jpg"  
/>
         
         {/* SECCION HOTELES */}
      <div className='flex justify-center m-0 sm:m-0 md:m-5'>
      <AnimatedComponent animationType="fadeInDown" delay={0.5}>
      <HotelsSection data={newHotels}/>
      </AnimatedComponent>
      </div>
    <Divider/>
      {/* SECCION SERVICIOS */}

      {/* OFERTAS EXCLUSIVAS */}
      <AnimatedComponent animationType="zoomIn" delay={0.8}>
        <div className='pb-5'>
        <OffersSlider offers={Hotels} />
        </div>
      </AnimatedComponent>
    </div>
    
  );
}

export default Home;